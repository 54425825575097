export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"ETHPrague","content":"ETHPrague"},{"property":"og:title","content":"ETHPrague"},{"property":"og:image","content":"https://ethprague.com/ETHPrague_soc_share-2025.png"},{"property":"og:url","content":"https://ethprague.com/"},{"property":"og:type","content":"website"},{"property":"og:description","content":"ETHPrague"},{"name":"twitter:title","content":"ETHPrague"},{"name":"twitter:image","content":"https://ethprague.com/ETHPrague_soc_share-2025.png"},{"name":"twitter:url","content":"https://ethprague.com/"},{"name":"twitter:site","content":"@EthPrague"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:description","content":"ETHPrague"}],"link":[],"style":[],"script":[],"noscript":[],"title":"ETHPrague"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false