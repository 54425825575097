import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry }
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  // Disable Sentry on localhost
  if (window.location.hostname === 'localhost') {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      '*',
      'https://dev.ethprague.pages.dev',
      'https://ethprague.com'
    ],

    replaysSessionSampleRate: 0, // Change in prod
    replaysOnErrorSampleRate: 0 // Change in prod if necessary
  })
})
