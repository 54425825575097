<template>
  <ClientOnly>
    <base target="_blank" />
    <NuxtPage />
  </ClientOnly>
</template>

<script setup lang="ts">
import useContentful from '~/api/useContentful'
const { fetchContentfulData } = useContentful()

onMounted(() => {
  fetchContentfulData()
  // throw new Error('This is test error')
})
</script>

<style lang="stylus">

*
  box-sizing border-box

:root
  // colors
  --col-primary-accent #2af4e4
  --col-light-gray-bg rgb(0 0 0 / 8%)
  --col-primary-action #BC0090  // #b315a4

  --col-bg-1 #ebebeb
  --col-bg-2: #B8E4FA
  --col-bg-3: #2C2C5A
  --col-bg-4: #FFC612
  --col-text-1: #2C2C5A
  --col-text-2: #EF4C46
  --col-text-3: #2797FF
  --col-text-4: #FFC612

  --header-icon: #29AAE1
  --big-button-hover: #29AAE1

  // sizes
  --mobile-breakpoint 1120px
  --app-padding 15px
  --section-padding clamp(1rem, 2vw, 3rem)
  --section-title-image-height clamp(190px, 35vw, 300px)
  --section-top-padding 90px
  --section-top-margin calc(clamp(100px, 25vw, 230px) - var(--section-top-padding))
  --section-title-bottom-margin clamp(50px, 10vw, 100px)

html
  scroll-behavior smooth
  transition all 0.3s
  scroll-padding-top 90px

body *
  font-family "Archivo SemiExpanded", sans-serif

body
  margin 0
  background-color var(--col-bg-1)

::selection // Code for Firefox
  color var(--col-primary-action)
  background var(--col-primary-accent)


::selection
  color var(--col-primary-action)
  background var(--col-primary-accent)


@font-face
  font-family "Archivo SemiExpanded" //a name to be used later
  src url("~/assets/font/Archivo_SemiExpanded-Regular.ttf") //URL to font


@font-face
  font-family "Helvetica Neue"
  src url("~/assets/font/Helvetica.ttf")
  font-weight normal
  font-style normal


@font-face
  font-family "Helvetica Neue Bold"
  src url("~/assets/font/Helvetica-Bold.ttf")
  font-weight normal
  font-style normal

// Animation

// stylelint-disable-next-line keyframes-name-pattern
@keyframes fadeInUp
  0%
    transform translateY(50%)
    opacity 0

  70%
    opacity 0.5

  100%
    transform translateY(0%)
    opacity 1


.fadeInUp-animation
  animation 1s fadeInUp
</style>
