import { default as deckSkt5OzBer8Meta } from "/opt/buildhome/repo/src/pages/deck.vue?macro=true";
import { default as indexhGpynX1cdDMeta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as scheduleYvuV6v5OA0Meta } from "/opt/buildhome/repo/src/pages/schedule.vue?macro=true";
export default [
  {
    name: "deck",
    path: "/deck",
    component: () => import("/opt/buildhome/repo/src/pages/deck.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/src/pages/index.vue")
  },
  {
    name: "schedule",
    path: "/schedule",
    component: () => import("/opt/buildhome/repo/src/pages/schedule.vue")
  }
]